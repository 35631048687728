<template>
  <v-container
    v-if="result !== null"
    style="height:91.5vh;overflow:auto; margin: auto; border-radius: 5px; background:rgba(255,255,255,.95 ); padding:15px;"
  >
    <v-row
      no-gutters
      style="margin-bottom:15px; position:sticky; top:-15px; z-index:2; background:white;"
    >
      <v-col
        cols="12"
        class="d-flex"
        style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
      >
        <h6
          style="margin:auto;
              font-weight: bold;
              text-align: center;
              color: rgba(0, 0, 0, 0.7);
            "
        >
          DETAIL PERSETUJUAN
        </h6>
      </v-col>
      <v-col
        v-if="result.status === 1"
        cols="12"
        style="padding: 0; margin:5px 0 15px 0;"
      >
        <h5
          style="margin:auto;
              font-weight: bold;
              text-align: center;
              color: rgba(0, 0, 0, 0.7);
            "
        >
          PT Sanqua Multi Internasional
        </h5>
      </v-col>
    </v-row>
    <v-card elevation="0" style="background-color:#fffaef;">
      <v-row no-gutters style="padding: 10px 0; margin-top:25px;">
        <v-col cols="12" style="padding: 0; margin:15px 0 15px 0;">
          <h6
            style="margin:auto; font-size:18px; font-weight: bold; text-align: center;"
          >
            Document Verified
            <v-icon small>mdi-check-decagram</v-icon>
          </h6>
        </v-col>
        <v-col cols="12" style="padding: 0; margin:0;">
          <p
            style="text-align:center; font-size: 14px; font-weight: bold; color: rgba(0, 0, 0, 0.7); margin:0;"
          >
            No. dokumen : {{ result.document_no }}
          </p>
        </v-col>
        <v-col cols="12" style="padding: 0; margin:0 0 25px 0;">
          <p
            style="text-align:center; font-size: 14px; font-weight: bold; color: rgba(0, 0, 0, 0.7); margin:0;"
          >
            Tanggal : {{ result.document_date }}
          </p>
        </v-col>
        <!-- <v-col cols="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nomor dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.document_no"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
            </v-col>
          </div>
        </v-col> -->
        <!-- <v-col cols="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tanggal
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.document_date"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
            </v-col>
          </div>
        </v-col> -->
        <v-col cols="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nama aplikasi
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.application_name"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
            </v-col>
          </div>
        </v-col>

        <v-col cols="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Pemberi persetujuan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.approver_name"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Status dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-if="result.status === -1"
                value="Reject"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
              <v-text-field
                v-if="result.status === 0"
                value="Pending"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
              <v-text-field
                v-if="result.status === 1"
                value="Approved"
                dense
                readonly
                style="margin: 0;font-size:14px;"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" style="padding: 0 10px; margin:30px 0 15px 0;">
          <p style="font-size: 12px; margin:0; color:#5f5f5f;">
            Note: <br />
            Bila ada keraguan silahkan konfirmasi ulang kepada pihak terkait
          </p>
        </v-col>
      </v-row>
      <div style="position:fixed; z-index:2; bottom:10px; right:0;">
        <Scanner @loadPage="detail" /></div
    ></v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    Scanner: () => import('./Scanner.vue')
  },
  data() {
    return {
      oAuth: buildType.apiURL('oauth'),
      panel: [0, 1],
      result: null,

      reason: '',

      isAllow: false,
      matrix: [],

      approvalHeaders: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],
      wWidth: window.innerWidth
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.prevRoute = from
  //   })
  // },
  computed: {
    ...mapGetters(['getUserProfile'])
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  mounted() {
    this.detail()
    window.scrollTo(0, 0)
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    ...mapActions([]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    detail() {
      this.result = null
      axios
        .get(
          `${this.oAuth}approval_matrix/document/verify_approval?signature=VALIDATE_SIGNATURE|${this.$route.params.id}`
        )
        .then(res => {
          console.log(res.data)
          if (res.data.status_code === '00') {
            this.result = res.data.data
          }
          if (res.data.status_code === '-99') {
            this.showMsgDialog('warning', res.data.status_msg, false)
          }
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog('error', 'please contact an Admin', false)
        })
    },

    authorityCheck(item) {
      const matrixId = item.approval_matrix[0].document_id
      axios
        .get(
          `${this.oAuth}approval_matrix/document/allow_user?document_id=${matrixId}`
        )
        .then(res => {
          if (res.data.status_code !== '-99') {
            if (res.data.is_allow_approve !== 0) {
              this.isAllow = true
            } else {
              this.isAllow = false
            }
          } else {
            this.isAllow = false
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin',
            false
          )
        })
    },
    matrixCheck(item) {
      axios
        .get(
          `${this.oAuth}approval_matrix/document/list?limit=100&offset=0&keyword=&application_id=18&table_name=${item.approval_matrix[0].table_name}&document_id=${item.approval_matrix[0].document_id}`
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.matrix = res.data.data
          } else {
            this.showMsgDialog('error', res.data.status_msg, false)
          }
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin',
            false
          )
        })
    },
    fetch() {
      this.matrix = []
      const form = {
        act: 'fetch_matrix',
        document_id: this.result.approval_matrix[0].document_id,
        document_no: this.result.doc_no,
        application_id: 18,
        table_name: this.result.approval_matrix[0].table_name
      }
      axios
        .post(`${this.oAuth}approval_matrix/document/save`, form)
        .then(res => {
          if (res.status_code !== '-99') {
            this.showMsgDialog('success', res.status_msg, false)
            this.detail()
          } else {
            if (res.status_msg === 'Parameter value has problem') {
              this.showMsgDialog('error', res.error_msg[0].msg, false)
            } else {
              this.showMsgDialog('error', res.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin',
            false
          )
        })
    },
    rowClick(item) {
      console.log(item)
    },

    movementStatus(v) {
      switch (v) {
        case -2:
          return 'Canceled'

        case 1:
          return 'Pending'

        case 2:
          return 'Approved'

        default:
          return 'null'
      }
    },
    itemStatus(v) {
      switch (v) {
        case -1:
          return 'Rejected'
        case 0:
          return 'Pending'

        case 1:
          return 'Received'
        default:
          return 'null'
      }
    },
    color(v) {
      switch (v) {
        case -1:
          return 'hsla(10, 90%, 60%, 0.3)'

        case 1:
          return 'hsla(100, 50%, 50%, 0.3)'

        default:
          return 'white'
      }
    }
  }
}
</script>
